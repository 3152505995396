import {useEffect, useState} from 'react';

import {base64Decode, base64Encode} from '@/utils/base64';

import {useBrowserUrl} from './useBrowserUrl';

// 30 days in milliseconds
const PAID_SEARCH_TTL = 30 * 24 * 60 * 60 * 1000;
const PAID_SEARCH_SOURCES = ['google', 'yabing'];
const PAID_SEARCH_MEDIUM = 'cpc';
const PAID_SEARCH_LOCAL_STORAGE_KEY = 'opt_chk_paid_search';

const PAID_SEARCH_SIGNUP_TYPES = {
  google: 'opt_chk_paid_search_user_google',
  yabing: 'opt_chk_paid_search_user_yabing',
};

export default function usePaidSearchUser() {
  const currentURL = useBrowserUrl();
  const utmMedium = currentURL?.searchParams.get('utm_medium');
  const utmSource = currentURL?.searchParams.get('utm_source') || '';
  const isPaidSearchSession =
    utmMedium === PAID_SEARCH_MEDIUM && PAID_SEARCH_SOURCES.includes(utmSource);
  const [paidSearchSignupType, setPaidSearchSignupType] = useState<string[]>(
    [],
  );

  useEffect(() => {
    try {
      if (typeof window === 'undefined' || !window.localStorage) {
        return;
      }

      if (isPaidSearchSession) {
        const signupType =
          PAID_SEARCH_SIGNUP_TYPES[
            utmSource as keyof typeof PAID_SEARCH_SIGNUP_TYPES
          ];

        setLocalStorageItem({
          localStorage,
          key: PAID_SEARCH_LOCAL_STORAGE_KEY,
          value: signupType,
          ttl: PAID_SEARCH_TTL,
        });

        setPaidSearchSignupType([signupType]);

        return;
      }

      const existingSignupType = getLocalStorageItem({
        localStorage,
        key: PAID_SEARCH_LOCAL_STORAGE_KEY,
      });

      if (existingSignupType) {
        setPaidSearchSignupType([existingSignupType]);
      }
    } catch (error) {
      return;
    }
  }, [isPaidSearchSession, utmSource]);

  return paidSearchSignupType;
}

function setLocalStorageItem({
  localStorage,
  key,
  value,
  ttl,
}: {
  localStorage: Storage;
  key: string;
  value: string;
  ttl: number;
}) {
  const now = new Date().getTime();

  const storage = JSON.stringify({
    value,
    expiry: now + ttl,
  });

  localStorage.setItem(key, base64Encode(storage));
}

function getLocalStorageItem({
  localStorage,
  key,
}: {
  localStorage: Storage;
  key: string;
}) {
  const item = localStorage.getItem(key);

  if (!item) {
    return null;
  }

  const parsedItem = JSON.parse(base64Decode(item));
  const now = new Date().getTime();

  if (now > parsedItem.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return parsedItem.value;
}
