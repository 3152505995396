import type {Region} from '@data/types';

import type {RegionSelectorItem} from './types';

export const getOrderedRegions = (
  selectors: Region[],
  selectedRegion?: RegionSelectorItem,
  regionSelectorLinks?: Set<RegionSelectorItem>,
  availableHreflangs?: Set<string>,
) => {
  const isEmptyRegionSelectorLinks =
    !regionSelectorLinks || regionSelectorLinks.size === 0;
  const hasSelectedRegionLabel = selectedRegion?.label;

  const getFilteredSelectors = () =>
    selectors.filter(
      (region) =>
        region.label !== selectedRegion?.label &&
        (availableHreflangs?.size === 0 ||
          availableHreflangs?.has(region.hreflang)),
    );

  const getOrderedRegionsFromSelectors = () =>
    hasSelectedRegionLabel
      ? [selectedRegion, ...getFilteredSelectors()]
      : [...selectors];

  return isEmptyRegionSelectorLinks
    ? getOrderedRegionsFromSelectors()
    : getOrderedRegionFromLinks({
        regionSelectorLinks,
        selectors,
        selectedRegion,
        hasSelectedRegionLabel,
      })();
};

export const getOrderedRegionFromLinks = ({
  regionSelectorLinks,
  selectors,
  selectedRegion,
  hasSelectedRegionLabel,
}: {
  regionSelectorLinks: Set<RegionSelectorItem> | undefined;
  selectors: Region[];
  selectedRegion?: RegionSelectorItem;
  hasSelectedRegionLabel?: string;
}) => {
  return () => {
    if (!regionSelectorLinks) {
      return [];
    }

    const orderedRegionItems = Array.from(regionSelectorLinks)
      .map((regionSelectorLink) => {
        const label =
          selectors.find(
            (selector) => selector.hreflang === regionSelectorLink.hreflang,
          )?.label || '';

        return {
          label,
          href: regionSelectorLink.href,
          hreflang: regionSelectorLink.hreflang,
          lang: regionSelectorLink.hreflang,
          isActive: false,
        };
      })
      .filter(
        (regionSelectorLink) =>
          selectedRegion?.label !== regionSelectorLink.label,
      );

    return hasSelectedRegionLabel
      ? [selectedRegion, ...orderedRegionItems]
      : orderedRegionItems;
  };
};
