// This file is auto generated by Verdict. Do not edit manually.

export type Flag<T> = T & {__brand: 'Flag'};

function flag<FlagType>(fValue: FlagType) {
  return fValue as Flag<FlagType>;
}

export const FLAG_RSI_RETAIL_PLAN_INCENTIVE = flag(
  'f_715c0e3a98dd480376df467707099539',
);
export const FLAG_RSI_POS_PRO_INCENTIVE = flag(
  'f_5a0b4347b30c0f8bd88d91c84ea31be1',
);
export const FLAG_PLUS_1800_HOLIDAY_AU = flag(
  'f_de77e6e530e9662a0d48c31eaa46533b',
);
export const FLAG_PLUS_1800_HOLIDAY_US_CA = flag(
  'f_a32d091a3b1b183267c0400ea9e951aa',
);
export const FLAG_US_DESKTOP_APP_CREDIT = flag(
  'f_7022eba69490e57ba01bbb27c682514c',
);
export const FLAG_BROCHURE_RETAIL_CARD_READER_DISCOUNT = flag(
  'f_e42205583aae645ce9b059f171ddfdab',
);
export const FLAG_DISCOUNTED_PRICE_VARIANT_SPAIN = flag(
  'f_96713d1055c363ddea2ae16668b8f8be',
);

type StrongTypeFlags =
  | typeof FLAG_RSI_RETAIL_PLAN_INCENTIVE
  | typeof FLAG_RSI_POS_PRO_INCENTIVE
  | typeof FLAG_PLUS_1800_HOLIDAY_AU
  | typeof FLAG_PLUS_1800_HOLIDAY_US_CA
  | typeof FLAG_US_DESKTOP_APP_CREDIT
  | typeof FLAG_BROCHURE_RETAIL_CARD_READER_DISCOUNT
  | typeof FLAG_DISCOUNTED_PRICE_VARIANT_SPAIN;

export const ALL_FLAGS = [
  'f_715c0e3a98dd480376df467707099539',
  'f_5a0b4347b30c0f8bd88d91c84ea31be1',
  'f_de77e6e530e9662a0d48c31eaa46533b',
  'f_a32d091a3b1b183267c0400ea9e951aa',
  'f_7022eba69490e57ba01bbb27c682514c',
  'f_e42205583aae645ce9b059f171ddfdab',
  'f_96713d1055c363ddea2ae16668b8f8be',
] as StrongTypeFlags[];

type ExtractString<T> = T extends Flag<infer S> ? S : never;

export type Flags = StrongTypeFlags | ExtractString<StrongTypeFlags>;
