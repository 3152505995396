import {useContext} from 'react';

import {usePageData} from '@/components/shared/Page/PageData';
import {VerdictLookupContext} from '@/VerdictLookupContext';
import type {Flags} from '@/flags';

/**
 * Returns the experiment variant for the given experiment handle.
 */
export const useFlag = (flagHandle: Flags): boolean => {
  const verdictLookupContext = useContext(VerdictLookupContext);
  verdictLookupContext.add(flagHandle);

  // features use the same experimentVariationId well-formed strings as experiments
  const {experimentVariationId = ''} = usePageData();

  if (!experimentVariationId) return false;

  const flag = experimentVariationId.split(',').find((exp: string) => {
    const [handle] = exp.split(':');
    return handle === flagHandle;
  });

  if (flag) {
    const [, enabled] = flag.split(':');
    return enabled === 'true';
  }

  return false;
};
