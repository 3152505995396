import {useSiteData} from '@/hooks/useSiteData';
import useHandle from '@/hooks/useHandle';
import {signupName, signupUrl} from '@/hooks/navigation/utils';
import {useTranslations} from '@/hooks/useTranslations';
import {usePaidTrialLengthPromotion} from '@/hooks/experiments/usePaidTrialLengthPromotion';
import {useGuidanceSignupType} from '@/hooks/experiments/useGuidanceSignupType';
import {useIntlAcquisitionIncentive} from '@/hooks/experiments/useIntlAcquisitionIncentive';
import {useGmvRewardsAcquisitionIncentive} from '@/hooks/experiments/useGmvRewardsAcquisitionIncentive';

import {useReplatformerSignupType} from './useReplatformerSignupType';
import usePaidSearchUser from './usePaidSearchUser';
import {useRetailSignupType} from './experiments/useRetailSignupType';

// DUX already has a hook called useSignupUrl, so the file name is different
export default function useSignup(additionalSignUpTypes?: string[]) {
  const {site, canonicalUrl} = useSiteData();
  const handle = useHandle();
  const {signupTypes: paidTrialSignupTypes = []} =
    usePaidTrialLengthPromotion();

  const {signupType: retailSignupType = []} = useRetailSignupType();
  const {signupType: guidanceSignupType = []} = useGuidanceSignupType();
  const {signupType: replatformerSignupType = []} = useReplatformerSignupType();
  const {signupTypes: intlAcqIncentiveSignupType = []} =
    useIntlAcquisitionIncentive();
  const {signupTypes: gmvRewardsAcquisitionIncentiveSignupType} =
    useGmvRewardsAcquisitionIncentive();
  const paidSearchUserSignupTypes = usePaidSearchUser();

  const deeplinkSignupTypes = [
    ...paidTrialSignupTypes,
    ...guidanceSignupType,
    ...replatformerSignupType,
    ...intlAcqIncentiveSignupType,
    ...gmvRewardsAcquisitionIncentiveSignupType,
    ...paidSearchUserSignupTypes,
    ...retailSignupType,
    ...(additionalSignUpTypes || []),
  ];

  return {
    signupUrl: signupUrl(
      site,
      canonicalUrl as string,
      handle,
      deeplinkSignupTypes,
    ),
    signupName,
  };
}

export function useSignupParser(signup?: any) {
  const {t} = useTranslations();
  switch (signup?.type) {
    case 'disabled':
      return {};
    case 'disclaimer_only':
      return {
        disclaimerHtml:
          signup.disclaimerHtml || t('global:signup.disclaimerHtml'),
      };
    case 'button_only':
      return {
        buttonText: signup.buttonText || t('global:signup.buttonText'),
      };
    case 'no_email':
      return {
        buttonText: signup.buttonText,
        disclaimerHtml: signup.disclaimerHtml,
      };
    default:
      return {
        buttonText: signup?.buttonText || t('global:signup.buttonText'),
        disclaimerHtml:
          signup?.disclaimerHtml || t('global:signup.disclaimerHtml'),
        label: t('global:signup.label'),
        placeholder: t('global:signup.placeholder'),
      };
  }
}
